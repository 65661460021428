<template>
  <div class="d-flex d-sm-none">
    <TopCell />
  </div>
  <section id="curso_selecionado">
    <header>
      <div class="container">
        <div>
          <div class="row">
            <MenuMobileDois />

            <div class="col-md-6">
              <p class="title_curso" v-if="state.tipo_pessoa == 'PACIENTE'">
                PACIENTE
              </p>
              <p class="title_curso" v-if="state.tipo_pessoa == 'TUTOR_PET'">
                PET
              </p>
              <h1
                class="title_curso"
                v-if="state.tipo_pessoa == 'PROFISSIONAL_SAUDE'"
              >
                PROFISSIONAL DE SAÚDE
              </h1>
              <p class="title_curso" v-if="state.tipo_pessoa == 'PESQUISADOR'">
                PESQUISADOR
              </p>
              <p class="title_curso" v-if="state.tipo_pessoa == 'PARCEIRO'">
                PARCEIRO
              </p>
              <div class="d-flex d-sm-none div_nome_curso">
                <img src="/img/icon_apracam/img_cursos.png" class="icon_cel" />
                <div class="titulo_cel">
                  FORMULARIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </div>
              </div>
            </div>
            <div class="col-md-6" style="text-align: right">
              <a href="/">
                <img class="logoQuatro" src="../../../public/img/logo.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container" v-if="state.tipo_pessoa == 'PACIENTE'">
      <div class="div_form">
        <div style="background-color: #fff; border-radius: 20px">
          <div class="row">
            <div class="col-md-5">
              <label> POSSUI RECEITA? </label>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      JÁ TENHO RECEITA.<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="sim"
                    v-model="state.tem_receita"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda">
                    <p>
                      NÃO TENHO RECEITA,<br />
                      QUERO SER ACOLHIDO.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="opcao"
                    value="nao"
                    v-model="state.tem_receita"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div v-if="state.tem_receita == 'sim'">
                <label for="receita">RECEITA MÉDICA</label>
                <span class="campo-obrigatorio">(Obrigatório*)</span>
                <div class="inputFile">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="receita"
                    multiple
                    @change="addReceitaMedica"
                  />
                </div>
                <div>
                  <div v-for="(receita, index) in state.receitas" :key="index">
                    <div
                      v-if="
                        receita.file.type == 'image/jpeg' ||
                        receita.file.type == 'image/jpg' ||
                        receita.file.type == 'image/png'
                      "
                    >
                      <img :src="receita.receita" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarReceitaMedica(receita.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarReceitaMedica"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="state.tem_receita == 'sim'">
                <label for="data_emissao_receita">DATA DA RECEITA</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <input
                  type="text"
                  class="form-control"
                  id="data_emissao_receita"
                  v-mask-date.br
                  v-model="state.data_receita"
                />
              </div>

              <div class="form-group" v-if="state.tem_receita == 'sim'">
                <label for="id_medico">MÉDICO</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <select
                  class="form-select select_normal"
                  id="id_medico"
                  v-model="state.medico_id"
                >
                  <option
                    v-for="(medico, index) in state.medicos"
                    :key="index"
                    :value="medico.id"
                  >
                    {{ medico.nome }}
                  </option>
                  <option value="">Outro Médico</option>
                </select>
              </div>

              <div
                class="form-group"
                v-if="state.tem_receita == 'sim' && state.medico_id == ''"
              >
                <label for="nome_medico">NOME DO MÉDICO</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <input
                  type="text"
                  class="form-control"
                  id="nome_medico"
                  v-model="state.outro_medico"
                />
              </div>

              <label for="laudo">LAUDO MÉDICO</label>
              <div class="inputFile">
                <span class="arquivo">Selecione um arquivo</span>
                <input
                  type="file"
                  class="form-control"
                  @change="adicionarLaudoMedico"
                  multiple
                />
              </div>
              <div>
                <div v-for="laudo in state.laudos" :key="laudo">
                  <div
                    v-if="
                      laudo.file.type == 'image/jpeg' ||
                      laudo.file.type == 'image/jpg' ||
                      laudo.file.type == 'image/png'
                    "
                  >
                    <img :src="laudo.laudo" style="width: 100px" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarLaudoMedico(laudo.id)"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarLaudoMedico(laudo.id)"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="patologia">PATOLOGIA (DOENÇA)</label>
                <select
                  class="form-control d-block d-sm-none"
                  v-model="state.check_patologia"
                  multiple
                >
                  <option
                    v-for="patologia in state.patologias"
                    :key="patologia"
                    :value="patologia.id"
                  >
                    {{ patologia.nome }}
                  </option>
                </select>

                <div class="d-none d-sm-block">
                  <div class="row">
                    <div
                      class="col-sm-6"
                      v-for="patologia in state.patologias"
                      :key="patologia"
                    >
                      <label class="radio-container radio-container2">
                        <div class="conteudo_rolagem">
                          {{ patologia.nome }}
                        </div>
                        <input
                          type="checkbox"
                          :value="patologia.id"
                          v-model="state.check_patologia"
                          :true-value="[]"
                        />
                        <span class="checkboxmark2"></span>
                      </label>
                    </div>
                  </div>
                  <label class="radio-container radio-container2">
                    <div class="conteudo_rolagem">OUTRA PATOLOGIA</div>
                    <input
                      type="checkbox"
                      v-model="state.check_outra_patologia"
                    />
                    <span class="checkboxmark2"></span>
                  </label>
                </div>
                <div v-if="state.check_outra_patologia">
                  <label for="patologia">NOME DA PATOLOGIA</label>
                  <input
                    type="text"
                    class="form-control"
                    id="patologia"
                    v-model="state.outra_patologia"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="contribuir">QUAL TIPO DE ÓLEO PEDIDO?</label>
                <div class="d-block d-sm-none">
                  <select
                    class="form-control d-block d-sm-none"
                    v-model="state.check_tipo_oleo"
                    multiple
                  >
                    <option
                      v-for="tipo in state.tipos_oleo"
                      :key="tipo"
                      :value="tipo.id"
                    >
                      {{ tipo.nome }}
                    </option>
                  </select>
                </div>
                <div class="d-none d-sm-block">
                  <div class="row">
                    <div
                      class="col-sm-6"
                      v-for="tipo in state.tipos_oleo"
                      :key="tipo"
                    >
                      <label class="radio-container radio-container2">
                        <div class="conteudo_rolagem">
                          {{ tipo.nome }}
                        </div>
                        <input
                          type="checkbox"
                          :value="tipo.id"
                          v-model="state.check_tipo_oleo"
                          :true-value="[]"
                        />
                        <span class="checkboxmark2"></span>
                      </label>
                    </div>
                  </div>
                  <label class="radio-container radio-container2">
                    <div class="conteudo_rolagem">OUTRO TIPO DE ÓLEO</div>
                    <input
                      type="checkbox"
                      v-model="state.check_outro_tipo_oleo"
                    />
                    <span class="checkboxmark2"></span>
                  </label>
                </div>
                <div v-if="state.check_outro_tipo_oleo">
                  <label for="patologia">NOME DO OUTRO TIPO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="patologia"
                    v-model="state.outro_tipo_oleo"
                  />
                </div>
              </div>

              <div class="form-group">
                <h2 class="contribuir">POSSUI RESPONSÁVEL LEGAL?</h2>
                <label class="radio-container">
                  <div class="borda conteudo_responsavel">NÃO.</div>
                  <input
                    type="radio"
                    value="nao"
                    v-model="state.opcao_responsavel"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <label class="radio-container">
                  <div class="borda conteudo_responsavel">SIM.</div>
                  <input
                    type="radio"
                    value="sim"
                    v-model="state.opcao_responsavel"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group" v-if="state.opcao_responsavel == 'sim'">
                <label for="nome_responsavel">NOME DO RESPONSÁVEL</label>
                <input
                  type="text"
                  class="form-control"
                  id="nome_responsavel"
                  v-model="state.nome_responsavel"
                />
                <label for="receita">DOCUMENTO DO RESPONSÁVEL</label>
                <div class="inputFile">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    @change="adicionardocsResponsavel"
                    multiple
                  />
                </div>
                <div>
                  <div
                    v-for="doc_responsavel in state.docs_responsavel"
                    :key="doc_responsavel"
                  >
                    <div
                      v-if="
                        doc_responsavel.file.type == 'image/jpeg' ||
                        doc_responsavel.file.type == 'image/jpg' ||
                        doc_responsavel.file.type == 'image/png'
                      "
                    >
                      <img :src="doc_responsavel.imagem" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarDocR(doc_responsavel.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarReceitaMedica"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <label for="documento"
                >DOCUMENTO DE IDENTIFICAÇÃO (PACIENTE)</label
              ><span class="campo-obrigatorio">(Obrigatório*)</span>
              <div class="inputFile">
                <span class="arquivo">Selecione um arquivo</span>
                <input
                  type="file"
                  class="form-control"
                  multiple
                  @change="addDocPaciente"
                />
              </div>
              <div>
                <div
                  v-for="doc_paciente in state.docs_paciente"
                  :key="doc_paciente"
                >
                  <div
                    v-if="
                      doc_paciente.file.type == 'image/jpeg' ||
                      doc_paciente.file.type == 'image/jpg' ||
                      doc_paciente.file.type == 'image/png'
                    "
                  >
                    <img
                      :src="doc_paciente.doc_paciente"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarDocP(doc_paciente.id)"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>

                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarDocP(doc_paciente.id)"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <label for="residencia">COMPROVANTE DE RESIDÊNCIA</label>
              <div
                class="inputFile"
                v-if="Object.keys(state.comprovante_residencia).length === 0"
              >
                <span class="arquivo">Selecione um arquivo</span>

                <input
                  type="file"
                  class="form-control"
                  id="residencia"
                  @change="comprovanteResidencia"
                />
              </div>
              <div v-else>
                <div
                  v-if="
                    state.comprovante_residencia.file.type == 'image/jpeg' ||
                    state.comprovante_residencia.file.type == 'image/jpg' ||
                    state.comprovante_residencia.file.type == 'image/png'
                  "
                >
                  <img
                    :src="state.comprovante_residencia.residencia"
                    style="width: 100px"
                  />
                  <button
                    type="button"
                    class="btn_deletar"
                    @click="deletarComprovanteResidencia"
                  >
                    <img
                      src="../../../public/img/btn_deletar.png"
                      class="img_deletar"
                    />
                  </button>
                </div>
                <div v-else>
                  <img src="../../../public/img/pdf.png" />
                </div>
              </div>
              <!--<div class="form-group">
                  <h2 class="contribuir">
                    GOSTARIA DE CONTRIBUIR COM A APRACAM?
                  </h2>
                  <label class="radio-container"
                    >NÃO.
                    <input
                      type="radio"
                      name="opcao"
                      value="nao"
                      v-model="state.contribuicao"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form-group">
                  <label class="radio-container">
                    SIM.
                    <input
                      type="radio"
                      name="opcao"
                      value="sim"
                      v-model="state.contribuicao"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>-->
              <div class="btn_div">
                <div style="text-align: center" v-if="state.isLoader">
                  <Loader />
                </div>

                <button
                  @click="prosseguirPaciente"
                  type="button"
                  class="btn_inscricao"
                  :class="{ btn_inscricao_loader: state.isLoader }"
                >
                  PROSSEGUIR
                </button>
              </div>
            </div>
            <div class="col-md-4 d-none d-sm-block">
              <div class="text_form">
                <h1 class="form_text">
                  FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO
                  A CANNABIS MEDICINAL
                </h1>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="div_img">
                <img
                  class="icon_img"
                  src="../../../public/img/icon_apracam/seringa.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'TUTOR_PET'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-5">
            <div class="div_form">
              <form>
                <label for="receita">RECEITA MÉDICA</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <div class="inputFile">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    multiple
                    @change="addReceitaMedica"
                  />
                </div>
                <div>
                  <div v-for="receita in state.receitas" :key="receita">
                    <div
                      v-if="
                        receita.file.type == 'image/jpeg' ||
                        receita.file.type == 'image/jpg' ||
                        receita.file.type == 'image/png'
                      "
                    >
                      <img :src="receita.receita" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarReceitaMedica(receita.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarReceitaMedica(receita.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="laudo">NOME DO PET</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="state.nome_pet"
                    placeholder="Ex: Pipoca"
                  />
                </div>
                <div class="form-group">
                  <label for="patologia">PATOLOGIA (DOENÇA)</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="patologia"
                    v-model="state.patologia"
                  />
                </div>
                <div class="form-group">
                  <label for="tutor">TUTOR PET</label
                  ><span class="campo-obrigatorio">(Obrigatório*)</span>
                  <input
                    type="text"
                    class="form-control"
                    id="tutor"
                    v-model="state.tutor_pet"
                    placeholder="Ex: Jefferson"
                  />
                </div>
                <label for="documento"
                  >DOCUMENTO DE IDENTIFICAÇÃO (RG/CPF/CNH)</label
                ><span class="campo-obrigatorio">(Obrigatório*)</span>
                <div class="inputFile">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="documento"
                    @change="adicionardocsResponsavel"
                  />
                </div>
                <div>
                  <div
                    v-for="doc_responsavel in state.docs_responsavel"
                    :key="doc_responsavel"
                  >
                    <div
                      v-if="
                        doc_responsavel.file.type == 'image/jpeg' ||
                        doc_responsavel.file.type == 'image/jpg' ||
                        doc_responsavel.file.type == 'image/png'
                      "
                    >
                      <img :src="doc_responsavel.imagem" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarDocR(doc_responsavel.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarDocR"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <label for="residencia">COMPROVANTE DE RESIDÊNCIA</label>
                <div
                  class="inputFile"
                  v-if="Object.keys(state.comprovante_residencia).length === 0"
                >
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="residencia"
                    @change="comprovanteResidencia"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="
                      state.comprovante_residencia.file.type == 'image/jpeg' ||
                      state.comprovante_residencia.file.type == 'image/jpg' ||
                      state.comprovante_residencia.file.type == 'image/png'
                    "
                  >
                    <img
                      :src="state.comprovante_residencia.residencia"
                      style="width: 100px"
                    />
                    <button
                      type="button"
                      class="btn_deletar"
                      @click="deletarComprovanteResidencia"
                    >
                      <img
                        src="../../../public/img/btn_deletar.png"
                        class="img_deletar"
                      />
                    </button>
                  </div>
                  <div v-else>
                    <img src="../../../public/img/pdf.png" />
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguirPaciente"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-md-4">
            <div class="text_form">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/loves_pet.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PROFISSIONAL_SAUDE'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-4 offset-1">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="formacao">FORMAÇÃO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="formacao"
                    v-model="state.formacao"
                    placeholder="Ex: Ortopedista"
                  />
                </div>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>
                <label for="anexo">ANEXOS</label>
                <div class="inputFile" v-if="state.anexos.length === 0">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="adicionarAnexo"
                    multiple
                  />
                </div>
                <div v-else>
                  <div v-for="anexo in state.anexos" :key="anexo">
                    <div
                      v-if="
                        anexo.file.type == 'image/jpeg' ||
                        anexo.file.type == 'image/jpg' ||
                        anexo.file.type == 'image/png'
                      "
                    >
                      <img :src="anexo.anexo" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo(anexo.id)"
                        multiple
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/icon_equipe.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PESQUISADOR'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-4 offset-1">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="formacao">FORMAÇÃO</label>
                  <input
                    type="text"
                    class="form-control"
                    id="formacao"
                    v-model="state.formacao"
                    placeholder="Ex: Biólogo"
                  />
                </div>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>

                <label for="anexo">ANEXOS</label>
                <div class="inputFile" v-if="state.anexos.length === 0">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="adicionarAnexo"
                    multiple
                  />
                </div>
                <div v-else>
                  <div v-for="anexo in state.anexos" :key="anexo">
                    <div
                      v-if="
                        anexo.file.type == 'image/jpeg' ||
                        anexo.file.type == 'image/jpg' ||
                        anexo.file.type == 'image/png'
                      "
                    >
                      <img :src="anexo.anexo" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo(anexo.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo(anexo.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == true,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-3">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/icon_equipe.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="state.tipo_pessoa == 'PARCEIRO'">
      <div style="background-color: #fff; border-radius: 20px">
        <div class="row">
          <div class="col-md-6">
            <div class="div_form">
              <form>
                <div class="form-group">
                  <label for="proposta">PROPOSTA DE PARCERIA</label>
                  <textarea
                    class="form-control"
                    id="proposta"
                    v-model="state.proposta_parceria"
                  ></textarea>
                </div>
                <label for="anexo">ANEXOS</label>
                <div class="inputFile" v-if="state.anexos.length === 0">
                  <span class="arquivo">Selecione um arquivo</span>
                  <input
                    type="file"
                    class="form-control"
                    id="anexo"
                    @change="adicionarAnexo"
                    multiple
                  />
                </div>
                <div v-else>
                  <div v-for="anexo in state.anexos" :key="anexo">
                    <div
                      v-if="
                        anexo.file.type == 'image/jpeg' ||
                        anexo.file.type == 'image/jpg' ||
                        anexo.file.type == 'image/png'
                      "
                    >
                      <img :src="anexo.anexo" style="width: 100px" />
                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo(anexo.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                    <div v-else>
                      <img src="../../../public/img/pdf.png" />

                      <button
                        type="button"
                        class="btn_deletar"
                        @click="deletarAnexo(anexo.id)"
                      >
                        <img
                          src="../../../public/img/btn_deletar.png"
                          class="img_deletar"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="btn_div">
                  <div style="text-align: center" v-if="state.isLoader">
                    <Loader />
                  </div>
                  <button
                    @click="prosseguir"
                    type="button"
                    class="btn_inscricao"
                    :class="{
                      btn_inscricao_loader: state.isLoader == false,
                    }"
                  >
                    PROSSEGUIR
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-4 d-none d-sm-block">
            <div class="text_form_profissional">
              <h1 class="form_text">
                FORMULÁRIO DE CADASTRO APRACAM ASSOCIAÇÃO PARANAENSE DE APOIO A
                CANNABIS MEDICINAL
              </h1>
            </div>
          </div>
          <div class="col-md-2">
            <div class="div_img">
              <img
                class="icon_img"
                src="../../../public/img/icon_apracam/estufa_icon.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MenuMobileDois from "../../components/MenuMobileDois.vue";
import TopCell from "../../components/TopCell.vue";
import Loader from "./../Site/Layout/loader";
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStorage } from "vue3-storage";
import services from "../../services";
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  setup() {
    const storage = useStorage();
    const router = useRouter();
    const state = reactive({
      //PACIENTE
      tem_receita: "sim",
      data_receita: "",
      isLoader: false,
      patologia: "",
      check_patologia: [],
      check_oleo: [],
      nome_responsavel: "",
      opcao_responsavel: "nao",
      documento: "",
      nome_pet: "",
      tutor_pet: "",
      formacao: "",
      proposta_parceria: "",
      hash: "",
      comprovante_residencia: {},
      receitas: [],
      docs_responsavel: [],
      laudos: [],
      docs_paciente: [],
      arquivo: [],
      anexos: [],
      anexoUm: {},
      anexo: {},
      patologias: [],
      tipos_oleo: [],
      medicos: [],
      check_tipo_oleo: [],
      check_outra_patologia: false,
      check_outro_tipo_oleo: false,
      outra_patologia: null,
      outro_tipo_oleo: null,
    });
    onMounted(() => {
      if (router.currentRoute._value.params.tipo_pessoa != undefined) {
        state.tipo_pessoa = router.currentRoute._value.params.tipo_pessoa;
      }
      state.patologia = [];
      state.nome = storage.getStorageSync("nome");
      state.email = storage.getStorageSync("email");
      state.telefone = storage.getStorageSync("telefone");
      state.cpf = storage.getStorageSync("cpf");
      state.genero = storage.getStorageSync("genero");
      state.estado = storage.getStorageSync("estado");
      state.cidade = storage.getStorageSync("cidade");
      state.cep = storage.getStorageSync("cep");
      state.endereco = storage.getStorageSync("endereco");
      state.data_nascimento = storage.getStorageSync("data_nascimento");
      state.rua = storage.getStorageSync("rua");
      state.numero = storage.getStorageSync("numero");
      state.bairro = storage.getStorageSync("bairro");
      state.complemento = storage.getStorageSync("complemento");
      state.pessoa = storage.getStorageSync("pessoa");
      upperCase();
      fetchMedicos();
      fetchPatologias();
      fetchOleos();
    });

    async function fetchPatologias() {
      try {
        const { data } = await services.patologia.ativas();
        state.patologias = data.data;
      } catch (error) {
        alert("Ocorreu um erro, favor reinicie o cadastro.");
      }
    }

    async function fetchOleos() {
      try {
        const { data } = await services.tipos_oleo.ativas();
        state.tipos_oleo = data.data;
      } catch (error) {
        alert("Ocorreu um erro, favor reinicie o cadastro.");
      }
    }

    async function fetchMedicos() {
      try {
        const { data } = await services.medicos.ativos();
        state.medicos = data;
      } catch (error) {
        console.log(error);
        alert("Ocorreu um erro, favor reinicie o cadastro.");
      }
    }

    async function comprovanteResidencia(event) {
      const element = event.target.files[0];
      var objResidencia = new Object();
      //   objImagem.id = 0 + 1;
      objResidencia.file = element;
      objResidencia.residencia = URL.createObjectURL(element);
      state.comprovante_residencia = objResidencia;
    }

    async function deletarComprovanteResidencia() {
      state.comprovante_residencia = {};
    }

    async function addReceitaMedica(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];

        var objReceita = new Object();
        objReceita.id = state.receitas.length + 1;
        objReceita.file = element;
        objReceita.receita = URL.createObjectURL(element);
        state.receitas.push(objReceita);
      }
    }

    async function deletarReceitaMedica(index) {
      for (let i = 0; i < state.receitas.length; i++) {
        if (state.receitas[i].id == index) {
          console.log("Vai apagar esse: " + index);
          console.log("Vai ser nesse:" + state.receitas[i].id);
          state.receitas.splice(i, 1);
        }
      }
    }

    async function addDocPaciente(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index;
        objImagem.id = state.docs_paciente.length + 1;
        objImagem.file = element;
        objImagem.doc_paciente = URL.createObjectURL(element);
        state.docs_paciente.push(objImagem);
      }
    }

    /*
    async function docPaciente(event) {
      const element = event.target.files[0];
      var objDoc = new Object();
      //   objImagem.id = 0 + 1;
      objDoc.file = element;
      objDoc.doc_paciente = URL.createObjectURL(element);
      state.doc_paciente = objDoc;
      console.log(state.doc_paciente.file);
    }
*/

    async function deletarDocP(index) {
      for (let i = 0; i < state.docs_paciente.length; i++) {
        if (state.docs_paciente[i].id == index) {
          console.log("Vai apagar esse: " + index);
          console.log("Vai ser nesse:" + state.docs_paciente[i].id);
          state.docs_paciente.splice(i, 1);
        }
      }
    }

    async function adicionardocsResponsavel(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index;
        objImagem.file = element;
        objImagem.imagem = URL.createObjectURL(element);
        state.docs_responsavel.push(objImagem);
      }
    }

    /*
    async function docResponsavel(event) {
      const element = event.target.files[0];
      var objDoc = new Object();
      //   objImagem.id = 0 + 1;
      objDoc.file = element;
      objDoc.doc_responsavel = URL.createObjectURL(element);
      state.doc_responsavel = objDoc;
      console.log(state.doc_responsavel.file);
    }
    */
    async function deletarDocR(index) {
      for (let i = 0; i < state.docs_responsavel.length; i++) {
        if (state.docs_responsavel[i].id == index) {
          console.log("Vai apagar esse: " + index);
          console.log("Vai ser nesse:" + state.docs_responsavel[i].id);
          state.docs_responsavel.splice(i, 1);
        }
      }
    }

    async function adicionarLaudoMedico(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objImagem = new Object();
        objImagem.id = index;
        objImagem.file = element;
        objImagem.laudo = URL.createObjectURL(element);
        state.laudos.push(objImagem);
      }
    }
    /*
    async function laudoMedico(event) {
      const element = event.target.files[0];
      var objLaudo = new Object();
      //   objImagem.id = 0 + 1;
      objLaudo.file = element;
      objLaudo.laudo = URL.createObjectURL(element);
      state.laudo = objLaudo;
      console.log(state.laudo.file);
    }    
    */
    async function deletarLaudoMedico(index) {
      for (let i = 0; i < state.laudos.length; i++) {
        if (state.laudos[i].id == index) {
          //     console.log("Vai apagar esse: " + index);
          //   console.log("Vai ser nesse:" + state.laudos[i].id);
          state.laudos.splice(i, 1);
        }
      }
    }

    async function deletarAnexo(index) {
      for (let i = 0; i < state.anexos.length; i++) {
        if (state.anexos[i].id == index) {
          //     console.log("Vai apagar esse: " + index);
          //   console.log("Vai ser nesse:" + state.anexos[i].id);
          state.anexos.splice(i, 1);
        }
      }
    }

    async function adicionarAnexo(event) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        var objAnexo = new Object();
        objAnexo.id = index;
        objAnexo.file = element;
        objAnexo.anexo = URL.createObjectURL(element);
        state.anexos.push(objAnexo);
      }
    }

    async function anexoUm(event) {
      const element = event.target.files[0];
      var objanexoUm = new Object();
      objanexoUm.file = element;
      objanexoUm.anexoUm = URL.createObjectURL(element);
      state.anexoUm = objanexoUm;
      console.log(state.anexoUm.file);
    }

    function isValidBrazilianDate(dateString) {
      // Espera uma data no formato dd/mm/aaaa
      const [day, month, year] = dateString.split("/").map(Number);

      // Cria uma data com o mês sendo 0-indexado
      const date = new Date(year, month - 1, day);

      // Verifica se a data criada corresponde à data original
      return (
        !isNaN(date.getTime()) &&
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      );
    }

    async function prosseguirPaciente() {
      if (state.tem_receita == "sim") {
        if (state.receitas.length === 0) {
          toast.error("Insira uma receita!", {
            autoClose: 2000,
          });
          return;
        }
      }

      if (
        (!state.check_patologia || state.check_patologia.length == 0) &&
        !state.check_outra_patologia
      ) {
        toast.error("Insira uma patologia!", {
          autoClose: 2000,
        });
        return;
      }

      if (
        (!state.check_tipo_oleo || state.check_tipo_oleo.length == 0) &&
        !state.check_outro_tipo_oleo
      ) {
        toast.error("Insira um tipo de Oleo!", {
          autoClose: 2000,
        });
        return;
      }

      if (state.pessoa == "PACIENTE") {
        if (state.docs_paciente.length == 0) {
          toast.error("Insira o documento de identificação do paciente !", {
            autoClose: 2000,
          });
          return;
        }
      }
      if (state.opcao_responsavel == "sim") {
        if (state.docs_responsavel.length == 0) {
          toast.error("Insira o documento de identificação do responsável !", {
            autoClose: 2000,
          });
          return;
        }
      }
      state.isLoader = true;
      var formPaciente = new FormData();
      formPaciente.append("nome", state.nome);
      formPaciente.append("email", state.email);
      formPaciente.append("telefone", state.telefone);
      formPaciente.append("cpf", state.cpf);
      formPaciente.append("genero", state.genero);
      formPaciente.append("estado", state.estado);
      formPaciente.append("cidade", state.cidade);
      formPaciente.append("cep", state.cep);
      formPaciente.append("endereco", state.endereco);
      formPaciente.append("data_nascimento", state.data_nascimento);
      formPaciente.append("tipo_pessoa", state.pessoa);
      formPaciente.append("patologia", state.patologia);
      formPaciente.append("responsavel", state.nome_responsavel);
      formPaciente.append("rua", state.rua);
      formPaciente.append("numero", state.numero);
      formPaciente.append("bairro", state.bairro);
      formPaciente.append("complemento", state.complemento);
      formPaciente.append("patologias", state.check_patologia);
      formPaciente.append("tipos_oleo", state.check_tipo_oleo);
      if (state.data_receita) {
        if (isValidBrazilianDate(state.data_receita)) {
          formPaciente.append("data_receita", state.data_receita);
        } else {
          toast.error("Data da Receita inválida, favor verificar!", {
            autoClose: 2000,
          });
          state.isLoader = false;
          return;
        }
      }

      formPaciente.append("check_outra_patologia", state.check_outra_patologia);
      if (state.check_outra_patologia) {
        formPaciente.append("outra_patologia", state.outra_patologia);
      }

      formPaciente.append("check_outro_tipo_oleo", state.check_outro_tipo_oleo);
      if (state.check_outro_tipo_oleo) {
        formPaciente.append("outro_tipo_oleo", state.outro_tipo_oleo);
      }

      if (state.medico_id == "" && state.outro_medico) {
        formPaciente.append("nome_outro_medico", state.outro_medico);
      }

      if (state.medico_id) {
        formPaciente.append("medico_id", state.medico_id);
      }

      storage.setStorageSync("nome_responsavel", state.nome_responsavel);
      /*
      if (state.pessoa == "PET" || state.pessoa == "PACIENTE") {
        formPaciente.append("doc_paciente", state.doc_paciente.file);
      }*/
      if (
        state.comprovante_residencia.file != undefined &&
        state.comprovante_residencia.file != null
      ) {
        formPaciente.append(
          "comprovante_residencia",
          state.comprovante_residencia.file
        );
      }
      formPaciente.append("nome_pet", state.nome_pet);
      formPaciente.append("tutor_pet", state.tutor_pet);

      await services.cadastrar
        .cadastrarPaciente(formPaciente)
        .then((data) => {
          state.dados_paciente_salvo = data.data;
          storage.setStorageSync("hash", data.data.hash);
          storage.setStorageSync("id", data.data.id);
        })
        .catch((error) => {
          try {
            var valores = Object.values(error.response.data.errors);
            valores.forEach((element) => {
              element.forEach((element2) => {
                toast(element2, {
                  type: "error",
                  autoClose: 3000,
                });
              });
            });
          } catch {
            console.log(error.response.data);
            try {
              axios.post(
                "https://backendsiscentrion.centrion.com.br/api/criar-erros",
                {
                  cliente_id: 3,
                  servico_id: 2,
                  erro: error.response.data.errors, //"Erro ao cliente fazer o cadastro",
                  mensagem_erro: error.response.data.message,
                }
              );
            } catch {
              console.log("erro");
            }

            toast("Ocorreu um erro desconhecido", {
              type: "error",
              autoClose: 3000,
            });
            state.isLoader = false;
            return;
          }
          state.isLoader = false;
          return;
        });

      /*  try {
        var data = await services.cadastrar.cadastrarPaciente(formPaciente);
        if (data.data.pessoa_id) {
          state.dados_paciente_salvo = data.data;
          storage.setStorageSync("hash", data.data.hash);
          storage.setStorageSync("id", data.data.id);
        } else {
          toast.error("Erro ao cadastrar!", {
            autoClose: 2000,
          });
          state.isLoader = false;
          return;
        }
      } catch (error) {
        var valores = Object.values(error.response.data.errors);
        valores.forEach((element) => {
          element.forEach((element2) => {
            toast(element2, {
              type: "error",
              autoClose: 3000,
            });
          });
        });
        state.isLoader = false;
        return;
      }*/

      var erros = [];

      /* Enviar Documentos */
      // Se tiver Receita
      if (state.tem_receita == "sim") {
        for (let i = 0; i < state.receitas.length; i++) {
          var formReceitas = new FormData();
          formReceitas.append("paciente_id", state.dados_paciente_salvo.id);
          formReceitas.append("data_receita", state.data_receita);
          formReceitas.append("tipo", "RECEITA");
          formReceitas.append("documento", state.receitas[i].file);
          await services.cadastrar.saveDocsPacientes(formReceitas).catch(() => {
            toast(
              "Erro ao adicionar Receita. Verifique o tamanho e tipo de arquivo.",
              {
                type: "error",
                autoClose: 3000,
              }
            );
            erros.push("erro_receita");
          });
        }
      }

      //Laudos
      for (let i = 0; i < state.laudos.length; i++) {
        var formLaudos = new FormData();
        formLaudos.append("paciente_id", state.dados_paciente_salvo.id);
        formLaudos.append("tipo", "LAUDO");
        formLaudos.append("documento", state.laudos[i].file);
        await services.cadastrar.saveDocsPacientes(formLaudos).catch(() => {
          toast(
            "Erro ao adicionar Laudo. Verifique o tamanho e tipo de arquivo.",
            {
              type: "error",
              autoClose: 3000,
            }
          );
          erros.push("erro_laudo");
        });
      }

      //Docs Responsavel
      if (state.opcao_responsavel == "sim") {
        for (let i = 0; i < state.docs_responsavel.length; i++) {
          var formResponsavel = new FormData();
          formResponsavel.append("paciente_id", state.dados_paciente_salvo.id);
          formResponsavel.append("tipo", "DOC_RESPONSAVEL");
          formResponsavel.append("documento", state.docs_responsavel[i].file);
          await services.cadastrar
            .saveDocsPacientes(formResponsavel)
            .catch(() => {
              toast(
                "Erro ao adicionar Doc. Responsável. Verifique o tamanho e tipo de arquivo.",
                {
                  type: "error",
                  autoClose: 3000,
                }
              );
              erros.push("erro_doc_responsavel");
            });
        }
      }

      //Docs Paciente
      for (let i = 0; i < state.docs_paciente.length; i++) {
        var formDocsPaciente = new FormData();
        formDocsPaciente.append("paciente_id", state.dados_paciente_salvo.id);
        formDocsPaciente.append("tipo", "DOC_PACIENTE");
        formDocsPaciente.append("documento", state.docs_paciente[i].file);
        await services.cadastrar
          .saveDocsPacientes(formDocsPaciente)
          .catch(() => {
            toast(
              "Erro ao adicionar Doc. Paciente. Verifique o tamanho e tipo de arquivo.",
              {
                type: "error",
                autoClose: 3000,
              }
            );
            erros.push("erro_doc_paciente");
          });
      }
      state.isLoader = false;
      if (erros.length == 0) {
        router.push("/doe/contribuicao");
      }
    }
    async function prosseguir() {
      try {
        if (
          state.pessoa == "PROFISSIONAL_SAUDE" ||
          state.pessoa == "PESQUISADOR"
        ) {
          if (
            state.formacao == null ||
            state.formacao == undefined ||
            state.formacao == ""
          ) {
            toast.error("Insira uma formação !", {
              autoClose: 2000,
            });
            return;
          }
        }
        if (
          state.proposta_parceria == null ||
          state.proposta_parceria == undefined ||
          state.proposta_parceria == ""
        ) {
          toast.error("Insira uma proposta de paraceria !", {
            autoClose: 2000,
          });
          return;
        }
        /* if (
          state.contribuicao == null ||
          state.contribuicao == undefined ||
          state.contribuicao == ""
        ) {
          toast.error(
            "Por favor escolha se deseja contribuir com a APRACAM !",
            {
              autoClose: 2000,
            }
          );
          return;
        }*/
        state.isLoader = true;
        var formProfissional = new FormData();
        formProfissional.append("nome", state.nome);
        formProfissional.append("email", state.email);
        formProfissional.append("cpf", state.cpf);
        formProfissional.append("telefone", state.telefone);
        formProfissional.append("genero", state.genero);
        formProfissional.append("estado", state.estado);
        formProfissional.append("cidade", state.cidade);
        formProfissional.append("endereco", state.endereco);
        formProfissional.append("data_nascimento", state.data_nascimento);
        formProfissional.append("tipo_pessoa", state.pessoa);
        formProfissional.append("rua", state.rua);
        formProfissional.append("numero", state.numero);
        formProfissional.append("bairro", state.bairro);
        formProfissional.append("complemento", state.complemento);
        formProfissional.append("cep", state.cep);

        if (
          state.formacao != undefined &&
          state.formacao != null &&
          state.formacao != ""
        ) {
          formProfissional.append("formacao", state.formacao);
        }
        formProfissional.append("proposta_parceria", state.proposta_parceria);

        var medicos = await services.cadastrar.cadastrarProfissional(
          formProfissional
        );

        // Anexos
        for (let i = 0; i < state.anexos.length; i++) {
          var formReceitas = new FormData();
          formReceitas.append("medicos_id", medicos.data[1].id);
          formReceitas.append("arquivo", state.anexos[i].file);
          await services.cadastrar.saveAnexos(formReceitas);
        }
        toast.success("Cadastro Realizado com Sucesso!", {
          autoClose: 2000,
        });

        await sleep(2000);
        router.push("/");
        return;
      } catch (error) {
        state.isLoader = false;
        toast.error("Erro ao cadastrar!", {
          autoClose: 2000,
        });
        return;
      }
    }

    const sleep = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay));
    function upperCase() {
      var title = state.tipo_pessoa;
      //-> Convertendo a String para maiúsculo.
      state.tipo_pessoa = title.toUpperCase();
    }
    return {
      Loader,
      state,
      upperCase,
      prosseguir,
      deletarReceitaMedica,
      prosseguirPaciente,
      comprovanteResidencia,
      deletarComprovanteResidencia,
      adicionarLaudoMedico,
      deletarLaudoMedico,
      anexoUm,
      adicionarAnexo,
      adicionardocsResponsavel,
      deletarAnexo,
      deletarDocR,
      addReceitaMedica,
      addDocPaciente,
      deletarDocP,
      MenuMobileDois,
      TopCell,
      axios,
    };
  },
};
</script>
<style>
@import url("../../site.css");
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
  font-weight: 100;
}

.borda {
  padding: 5px;
  border: #16524e solid 1px;
  width: 100%;
  border-radius: 20px;
  font-weight: 400;
}

.div_com_rolagem {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
  padding: 10px;
  overflow: auto;
}

.conteudo_responsavel {
  padding: 20px;
  font-weight: 400;
}

.conteudo_rolagem {
  font-weight: 400;
}

.borda p {
  margin-bottom: 0;
  padding-left: 1rem;
  font-weight: 400;
}

.checkmark {
  border: #16524e solid 1px;
  border-radius: 10px;
}

.checkboxmark {
  border: #16524e solid 1px;
  border-radius: 0px;
}

.checkboxmark2 {
  border: #16524e solid 1px;
  border-radius: 0px;
}

header {
  background-color: rgb(22, 82, 78);
  padding-bottom: 50px;
  height: 90px;
}

#curso_selecionado {
  background-color: #fff;
}

.campo-obrigatorio {
  color: #84a4a1;
  font-size: 10pt;
  padding-left: 5px;
}

.title_curso {
  color: #fff;
  font-size: 25pt;
  padding-top: 20px;
  font-family: Yanone;
  letter-spacing: 0.2rem;
}

.btn_inscricao_loader {
  display: none !important;
}

.btn_inscricao {
  width: 150px;
  background: #1f6e69;
  color: #fff;
  font-size: 16pt;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 20px;
  transition: 0.4s;
  margin-top: 5px;
  display: block;
  font-family: Yanone;
  font-weight: 100;
}

.div_img_curso_destaque {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.logoQuatro {
  margin-left: 0;
  z-index: 100;
  width: 20%;
}

.img_destaque_curso {
  border-radius: 20px;
}

.btn_div {
  text-align: center;
}

label {
  font-family: Yanone;
  font-size: 16pt;
  color: rgb(22, 82, 78);
  margin-bottom: 0;
}

.btn_deletar {
  padding: 0;
  border: none;
  background: none;
}

.img_deletar {
  width: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: Yanone;
}

input,
select {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 12pt;
}

.select_normal {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  font-size: 12pt;
  background-color: #fff;
}

textarea {
  border: solid 1px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 30px;
  padding-left: none !important;
  font-size: 12pt;
  resize: none;
}

input:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}

textarea:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}

input::placeholder {
  color: rgba(17, 62, 59, 0.551);
}

.div_form {
  padding: 20px;
  margin-top: 2rem;
}

.text_form {
  text-align: left;
  margin-top: 0rem;
}

.form_text {
  font-family: Yanone;
  font-weight: 500;
  color: #84a4a1;
  font-size: 46pt;
  line-height: 1.2;
}

.select_city {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
  border-radius: 20px;
  padding: 20px;
  padding-left: none !important;
  font-size: 12pt;
  width: 100%;
  background: #fff;
}

.select_city:focus {
  border: solid 2px rgb(22, 82, 78);
  color: rgb(22, 82, 78);
}

.icon_img {
  width: 100%;
}

.div_img {
  margin-top: 15rem;
  text-align: left;
}

.text_form_profissional {
  margin-top: 10rem;
}

.radio-container2 {
  padding-left: 1.5rem !important;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 4.3rem;
  background-color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  width: 80%;
}

/* Estilo personalizado para o círculo do botão de opção */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container span {
  font-size: 16pt;
  border-radius: 10px;
  padding: 10px;
}

/* Estilo personalizado para o marcador do botão de opção */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
}

.radio-container .checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
}

.radio-container .checkboxmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  border-radius: 0;
  background-color: #fff;
}

.radio-container .checkboxmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 0;
  background-color: #fff;
}

/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkmark {
  background-color: none;
  border: solid 15px;
}

.radio-container input:checked ~ .checkmark2 {
  background-color: none;
  border: solid 0px;
}

/* Estilo personalizado para o marcador quando selecionado */
.radio-container input:checked ~ .checkboxmark {
  background-color: none;
  border: solid 15px;
}

.radio-container input:checked ~ .checkboxmark2 {
  background-color: none;
  border: rgb(22, 82, 78) solid 6px;
  padding: 5px;
}

/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after,
.radio-container .checkmark2::after {
  content: "";
  position: absolute;
  display: none;
}

/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkboxmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkmark::after {
  display: block;
}

/* Estilo personalizado para o ponto interno do marcador quando selecionado */
.radio-container input:checked ~ .checkboxmark::after {
  display: block;
}

/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}

/* Estilo personalizado para o ponto interno do marcador */
.radio-container .checkboxmark::after {
  top: 6px;
  left: 6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
}

.contribuir {
  font-weight: inherit;
  color: rgb(22, 82, 78);
}

.inputFile {
  width: 100%;
  height: 60px;
  position: relative;
  overflow: hidden;
  border: solid 1px rgb(22, 82, 78);
  border-radius: 20px;
  margin-bottom: 15px;
}

.inputFile input {
  opacity: 0;
  filter: alpha(opacity=0);
  right: 0;
  padding-top: 5px;
  z-index: 2;
  height: 80px;
  font-size: 100px;
  color: #000;
}

.arquivo {
  position: absolute;
  top: 15px;
  color: #16524e;
  margin-left: 15px;
  font-weight: 700;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Espaçamento entre fileiras */
}

@media (min-width: 992px) and (max-width: 1200px) {
  .radio-container {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 4.3rem;
  }

  label {
    font-size: 13pt;
  }

  .form_text {
    font-size: 30pt;
  }

  .logoQuatro {
    display: none;
  }

  .text_form {
    margin-top: 22rem;
  }

  .div_img {
    margin-top: 20rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .radio-container {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 4.3rem;
  }

  .icon_img {
    display: none;
  }

  label {
    font-size: 15pt;
  }

  .form_text {
    font-size: 30pt;
  }

  .logoQuatro {
    display: none;
  }

  .arquivo {
    margin-left: 10px;
  }

  .btn_inscricao {
    width: 85%;
  }

  .btn_inscricao:hover {
    width: 90%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .logoQuatro {
    display: none;
  }

  .form_text {
    font-size: 20pt;
  }

  .div_img {
    margin-top: 0;
  }

  .text_form {
    text-align: center;
    margin-top: 0;
  }

  .icon_img {
    display: none;
  }
}

@media (max-width: 575px) {
  .titulo_cel {
    font-family: Yanone;
    font-size: 14pt;
    color: #feb715;
    padding-top: 1rem;
    text-align: right;
  }

  header {
    height: auto;
  }

  .icon_cel {
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }

  .icon_img {
    display: none;
  }

  .logoQuatro {
    display: none;
  }

  .form_text {
    font-size: 20pt;
  }

  .text_form {
    text-align: center;
    margin-top: 0;
  }

  .div_img {
    margin-top: 0;
  }

  .div_nome_curso {
    width: 100%;
  }
}
</style>